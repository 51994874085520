import React from 'react'

export interface InputChangedHandler {
    (value: string): void
}

export interface Option {
    value: string | number
    text: string
    disabled?: boolean
}

export const DEFAULT_VALUE = ''

export const getOptionElements = (options: Array<Option>, defaultText = '') => {
    let optionElements = options.map((opt, ix) =>
        <option key={ix + 1} value={opt.value} disabled={opt.disabled}>{opt.text}</option>)

    if (defaultText)
        optionElements.unshift(<option key={0} value={DEFAULT_VALUE} disabled hidden>{defaultText}</option>)

    return optionElements
} 

