import React from 'react'
import { PifUpdateOperation } from '../../logic/Form'

export interface PifUpdatedHandler { (operation: PifUpdateOperation): void }

export interface EditPifContextType {
    updatePif: PifUpdatedHandler
}

export const EditPifContext = React.createContext<EditPifContextType>({
    updatePif: () => ''
})

export default EditPifContext
