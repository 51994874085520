import { JsonApi, isString, FileResult } from './Constants'
import { Authentication } from './Authentication'

function getPathString<ReturnType>(api: JsonApi<ReturnType>, id?: string | number, formType?: string): string {
    let pathString: string

    if (isString(api.path)) {
        pathString = api.path
    }
    else {
        if (formType && id) {
            pathString = api.path(id, formType)
        }
        else if (id) {
            pathString = api.path(id)
        }
        else {
            pathString = api.path("")
        }
    }

    return pathString
}

export interface ResponseHandler {
    (reponse: Response): void
}

let errorHandler: ResponseHandler

export function setErrorHandler(handler: ResponseHandler) {
    errorHandler = handler
}

function addAuthorizationHeader(requestInit: RequestInit) {
    requestInit.headers = requestInit.headers || {}
    var authenticationResult = Authentication.get()

    if (authenticationResult)
        requestInit.headers = { ...requestInit.headers, ...{ Authorization: 'Bearer ' + authenticationResult.token } }
}

interface RequestParams<DataType> {
    id?: string | number
    formType?: string
    data?: DataType
}

function getQueryString<DataType>(data: DataType): string {
    let params = new URLSearchParams();

    for (const [key, val] of Object.entries(data)) {
        params.append(key, val)
    }

    return params.toString()
}

const EXCEL_CONTENT_TYPE = 'application/vnd.ms-excel'

function getFileName(response: Response): string {
    var contentDisposition = response.headers.get('content-disposition')!
    var matches = /.+filename="(.+)";/.exec(contentDisposition)!
    return matches[1]
}

// TODO: refactor common functionality
export const json = {
    getFile: function <DataType>(api: JsonApi<FileResult>, params?: RequestParams<DataType>): Promise<FileResult | undefined> {
        let path = getPathString(api, params && params.id)

        if (params && params.data) {
            path += ('?' + getQueryString(params.data))
        }

        let requestInit: RequestInit = {}

        if (!api.notAuthenticated) addAuthorizationHeader(requestInit)

        return fetch(path, requestInit)
            .then(async response => {
                if (response.ok) {
                    let fileName = getFileName(response)
                    let fileContents = await response.arrayBuffer()
                    return {
                        fileName, 
                        fileContents
                    }
                }
                else errorHandler && errorHandler(response)
            })
            .catch(err => {
                console.log('error with path: ' + path)
                console.log(err)
                throw err
            })
    },

    get: function <ReturnType, DataType>(api: JsonApi<ReturnType>, params?: RequestParams<DataType>): Promise<ReturnType> {

        let path = getPathString(api, params && params.id, params && params.formType)

        if (params && params.data) {
            path += ('?' + getQueryString(params.data))
        }

        let requestInit: RequestInit = {}

        if (!api.notAuthenticated) addAuthorizationHeader(requestInit)

        return fetch(path, requestInit)
            .then(response => {
                if (response.ok) return response.json()
                else errorHandler && errorHandler(response)
            })
            .catch(err => {
                console.log('error with path: ' + path)
                console.log(err)
                throw err
            })
    },

    post: function <ReturnType, DataType>(api: JsonApi<ReturnType>, params: RequestParams<DataType>): Promise<ReturnType> {
        let path = getPathString(api, params && params.id, params && params.formType)

        let requestInit: RequestInit = { method: 'POST', cache: 'no-cache' }

        if (params.data) {
            if (params.data instanceof FormData) {
                requestInit.body = params.data
            }
            else {
                requestInit.body = JSON.stringify(params.data)
                requestInit.headers = { 'Content-Type': 'application/json' }
            }
        }

        if (!api.notAuthenticated) addAuthorizationHeader(requestInit)

        return fetch(path, requestInit)
            .then(response => {
                if (response.ok) return response.json()
                else errorHandler && errorHandler(response)
            })
            .catch(err => {
                console.log('error with path: ' + path)
                console.log(err)
                throw err
            })
    },
}

export const http = { json }

export default http