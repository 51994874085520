import React from 'react'

import { Assignment } from '../models/FormItem'
import Table from './Table'
import { HierarchyItem } from '../models/Hierarchy';

export interface BmpItem {
    categoryName: string
    intakeName: string
}

function getPifBmps(deployments: Array<Assignment>, projectCategories: Array<HierarchyItem>, intakes: Array<HierarchyItem>): Array<BmpItem> {
    let pifBmps = deployments.map(d => {
        let projectCategory = projectCategories.find(bmp => bmp.id == d.bmpKey && bmp.parentId == d.intakeKey)
        let intake = intakes.find(i => i.id == d.intakeKey)

        let [categoryName, intakeName] = (projectCategory && intake) ? [projectCategory.name, intake.name] : ['', '']

        return { categoryName, intakeName }
    })

    return pifBmps
}

interface BmpTableProps {
    deployments: Array<Assignment>
    projectCategories: Array<HierarchyItem>
    intakes: Array<HierarchyItem>
}

export const BmpTable = (props: BmpTableProps) => {
    let bmps = getPifBmps(props.deployments, props.projectCategories, props.intakes)

    if (bmps.length == 0) return null

    else {
        let columns: Array<keyof BmpItem> = ['intakeName', 'categoryName']

        let headerNames: BmpItem = { intakeName: 'Intake', categoryName: 'BMP' }

        return (
            <Table {...{ columns, headerNames, data: bmps }} />
        )
    }
}

export default BmpTable