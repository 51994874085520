import React from 'react'

import Constants from '../Constants'
import { Authentication } from '../Authentication'
import { AppContext } from '../AppContext'
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import './NavMenu.css'

export interface NavMenuState {
    collapsed: boolean
}

export class NavMenu extends React.Component<{}, NavMenuState> {
    static displayName = NavMenu.name

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: {}) {
        super(props)

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    handleLogout = (e: React.MouseEvent<any, MouseEvent>) => {
        e.preventDefault()
        this.context.logout()
    }

    render() {
        let loginLink = null
        let authResult = Authentication.get()

        if (authResult) {
            loginLink =
                <NavLink tag={Link} className="text-dark" to={Constants.Paths.Web.login} onClick={this.handleLogout}>Logout {authResult.firstName}</NavLink>
        }
        else {
            loginLink =
                <NavLink tag={Link} className="text-dark" to={Constants.Paths.Web.login}>Login</NavLink>
        }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to={Constants.Paths.Web.selectPif}>PIF Manager</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    {loginLink}
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }
}
