import React from 'react'
import { StatelessComponent } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export interface ErrorModalProps { errorHtml?: string, onDismiss: () => void }

export const ErrorModal: StatelessComponent<ErrorModalProps> = (props: ErrorModalProps) => (
    <Modal isOpen={!!props.errorHtml} toggle={props.onDismiss} color='danger' size='xl'>
        <ModalHeader toggle={props.onDismiss}>Error</ModalHeader>
        <ModalBody>
            <div dangerouslySetInnerHTML={{__html: props.errorHtml || ''}}>
            </div>
        </ModalBody>
    </Modal>
)
