import React from 'react';
import Constants from '../Constants'
import { json } from '../http'

import { Col, Button, FormGroup, Label, Input } from 'reactstrap';

import AppContext from '../AppContext'

import Marquee from '../components/Marquee'

export class Login extends React.Component {
    static displayName = Login.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: any) {
        super(props)
        this.formRef = React.createRef<HTMLFormElement>()
    }

    componentDidMount() {
        this.formRef.current!.addEventListener('submit', this.handleSubmit)
    }

    componentWillUnmount() {
        this.formRef.current!.removeEventListener('submit', this.handleSubmit)
    }

    formRef: React.RefObject<HTMLFormElement>

    handleSubmit = async (e: Event) => {
        e.preventDefault()

        let data = new FormData(this.formRef.current!)

        try {
            let authResult = await json.post(Constants.Paths.Api.Users.Authenticate, { data })

            this.context.login(authResult)
        }
        catch (ex) {
            // http module handles unauthorized error with a popup
            // Possible TODO: could add form field validation and highlighting
        }
    }

    render() {
        return (
            <form ref={this.formRef} method='POST' action={Constants.Paths.Api.Users.Authenticate.path as string}>
                <Marquee />
                <FormGroup row>
                    <Label for='UserName' sm={{ size: 3, offset: 3 }}>Username: </Label>
                    <Col sm={5}>
                        <Input id='UserName' name='UserName' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='Password' sm={{ size: 3, offset: 3 }}>Password: </Label>
                    <Col sm={5}>
                        <Input type='password' id='Password' name='Password' />
                    </Col>
                </FormGroup>
                <FormGroup check row>
                    <Col sm={{ size: 3, offset: 6 }}>
                        <Button>Login</Button>
                    </Col>
                </FormGroup>
            </form>
        )
    }
}
