import React from 'react'

import { AppContext } from '../AppContext'
import { FormDefinitionsImportResult } from '../models/FormItem'

export interface ImportFormsReportProps {
    importResult: FormDefinitionsImportResult
    wasSinglePifReplaced?: boolean
}

export class ImportFormsReport extends React.Component<ImportFormsReportProps> {
    static displayName = ImportFormsReport.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: ImportFormsReportProps) { super(props) }

    render() {
        if (this.props.wasSinglePifReplaced) {
            let pif = this.props.importResult.createdForms[0]
            return <p>{`Successfully replaced PIF with ID ${pif.formId} (${pif.name})`}</p>
        }

        else {
            var deletedReport = this.props.importResult.deletedForms
                .map((p, ix) => {
                    return <li key={ix}>Deleted {p.name} (ID: {p.formId})</li>
                })

            var createdReport = this.props.importResult.createdForms
                .map((p, ix) => {
                    return <li key={ix}>Created {p.name} (ID: {p.formId})</li>
                })

            var updatedReport = this.props.importResult.updatedForms
                .map((p, ix) => {
                    return <li key={ix}>Updated {p.name} (ID: {p.formId})</li>
                })

            // because flat and flatmap aren't supported by edge
            let assignedReport: Array<JSX.Element> = []
            let ix = 0
            this.props.importResult.assignedForms
                .forEach(p => {
                    if (p.assignmentsPIF) {
                        p.assignmentsPIF.forEach(a => {
                            var bmp = this.context.findById('bmps', a.bmpKey)
                            assignedReport.push(<li key={ix++}>Assigned {p.name} (ID: {p.formId}) to {bmp.name}</li>)
                        });
                    }
                    if (p.assignmentsSystemBonus) {
                        p.assignmentsSystemBonus.forEach(a => {
                            var bmp = this.context.findById('bmps', a.bmpKey)
                            assignedReport.push(<li key={ix++}>Assigned {p.name} (ID: {p.formId}) to {bmp.name}</li>)
                        });
                    }
                })

            return (
                <div>
                    <h4>Deleted Forms</h4>
                    <ul>
                        {deletedReport}
                    </ul>
                    <h4>Created Forms</h4>
                    <ul>
                        {createdReport}
                    </ul>
                    <h4>Updated Forms</h4>
                    <ul>
                        {updatedReport}
                    </ul>
                    <h4>Assigned Forms</h4>
                    <ul>
                        {assignedReport}
                    </ul>
                </div>
            )
        }
    }
}




