import React from 'react'

import { AppContext } from '../AppContext'
import { FormDeploymentResult, FormItem } from '../models/FormItem'

export interface DeploymentReportProps {
    deploymentResult: FormDeploymentResult
}

export class DeploymentReport extends React.Component<DeploymentReportProps> {
    static displayName = DeploymentReport.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: DeploymentReportProps) { super(props) }

    getDeploymentReport(header: string, formItems?: Array<FormItem>): JSX.Element | null {
        let report: JSX.Element | null = null

        if (formItems && formItems.length > 0) {
            let reportItems =  formItems
                .map((formItem, ix) => {

                    var assignments = formItem.assignmentsPIF.map((d, dix) => {
                        var bmp = this.context.findById('bmps', d.bmpKey)
                        return <li key={dix}>Assigned to {bmp.name}</li>
                    })
                    if (assignments.length == 0)
                    {
                        assignments = formItem.assignmentsSystemBonus.map((d, dix) => {
                            var bmp = this.context.findById('bmps', d.bmpKey)
                            return <li key={dix}>Assigned to {bmp.name}</li>
                        })
                    }
                    return (
                        <li key={ix}>Deployed {formItem.name} (ID: {formItem.formId}) 
                            <ul>
                                {assignments}
                            </ul>
                        </li>
                    )
                })

            report = (
                <div>
                    <h4>{header}</h4>
                    {reportItems}
                </div>
            )
        }

        return report
    }

    render() {
        let capReport = this.getDeploymentReport('Deployed to CAP', this.props.deploymentResult.capForms)
        let dirtReport = this.getDeploymentReport('Deployed to DIRT', this.props.deploymentResult.dirtForms)

        return (
            <div>
                {capReport}
                {dirtReport}
            </div>
        )
    }
}




