import { Item, HierarchyItem, FormType } from './models/Hierarchy'
import { Form } from './models/Form'
import { FormItem, Assignment, FormDefinitionsImportResult, FormDeploymentResult, FormResponsesImportResult } from './models/FormItem'

import { AuthenticationResult } from './Authentication';

export type PathType = string | { (id?: string | number): string } | { (id?: string | number, formType?:string ):string}

export function isString(path: PathType): path is string {
    return typeof path === 'string'
}

export interface JsonApi<ReturnType> {
    path: PathType
    notAuthenticated?: boolean
}

export interface FileResult {
    fileName: string
    fileContents: ArrayBuffer
}

export interface Settings {
    environment: string
}

export const PIF_FORM_TYPE: FormType = 'pif'
export const SYSTEMS_BONUS_FORM_TYPE: FormType = 'systembonus'

class HierarchyApi {
    static FormTypes: Array<HierarchyItem> = [
        { isDirt: false, isPortal: false, name: "PIF", id: PIF_FORM_TYPE },
        { isDirt: false, isPortal: false, name: "System Bonus", id: SYSTEMS_BONUS_FORM_TYPE }
    ]
    static ApplicationTypes: JsonApi<Array<HierarchyItem>> = { path: '/api/hierarchy/applicationtypes' }
    static Intakes: JsonApi<Array<HierarchyItem>> =  { path: '/api/hierarchy/intakes' }
    static Bmps: JsonApi<Array<HierarchyItem>> = { path: (intakeKey?: string | number) => `/api/hierarchy/bmps/${intakeKey || ''}` }
}

class ProjectInformationFormsApi {
    static All: JsonApi<Array<FormItem>> = { path: '/api/projectinformationforms' }
    static One: JsonApi<Form> = { path: (pifId?: string | number) => `/api/projectinformationforms/${pifId}` }
    static QuestionTypes: JsonApi<Array<Item>> = { path: '/api/projectinformationforms/questiontypes' }
    static Create: JsonApi<FormItem> = { path: (formType?: string | number) => `/api/projectinformationforms/Create/${formType}` }
    static Update: JsonApi<boolean> = { path: (pifId?: string | number) => `/api/projectinformationforms/Update/${pifId}` }
    static Assign: JsonApi<boolean> = { path: (pifId?: string | number, formType?:string) => `/api/projectinformationforms/assign/${formType}/${pifId}` }
    static Delete: JsonApi<boolean> = { path: (pifId?: string | number) => `/api/projectinformationforms/delete/${pifId}` }
    static Copy: JsonApi<number> = { path: (pifId?: string | number, formType?: string) => `/api/projectinformationforms/copy/${formType}/${pifId}` }
    static Assignments: JsonApi<Array<Assignment>> = { path: (formType?: string | number) => `/api/projectinformationforms/assignments/${formType}` }
    static ImportDefinitions: JsonApi<FormDefinitionsImportResult> = { path: (formType?: string | number) => `/api/projectinformationforms/importdefinitions/${formType}` }
    static ImportResponses: JsonApi<FormResponsesImportResult> = { path: (formType?: string | number) => `/api/projectinformationforms/importresponses/${formType}` }
    static Export: JsonApi<FileResult> = { path: `/api/projectinformationforms/export` }
    static Deploy: JsonApi<FormDeploymentResult> = { path: (formType?: string | number) => `/api/projectinformationforms/deploy/${formType}` }
    static DataStewardPreview: JsonApi<FileResult> = { path: (intakeKey?: string | number) => `/api/projectinformationforms/datastewardpreview/${intakeKey}` }
    static DataStewardActive: JsonApi<FileResult> = { path: `/api/projectinformationforms/datastewardactive` }
    static ExcelAnswersTemplate: JsonApi<FileResult> = { path: `/api/projectinformationforms/excelanswerstemplate` }
}

class UsersApi {
    static Authenticate: JsonApi<AuthenticationResult> = { path: '/api/users/authenticate', notAuthenticated: true }
}

const SettingsApi: JsonApi<Settings> = { path: `api/settings` }

export const Constants = {
    Paths: {
        Web: {
            login: '/login',
            selectPif: '/select_pif',
            editPif: (pifId?: number) => `/edit_pif/${pifId || ':pifId'}`,
            createPif: (intakeKey?: string, formTypeKey?:string) => `/create_pif/${formTypeKey||':formTypeKey'}/${intakeKey || ':intakeKey'}`
        },
        Api: {
            Hierarchy: HierarchyApi,
            ProjectInformationForms: ProjectInformationFormsApi,
            Users: UsersApi,
            Settings: SettingsApi
        }
    }
}

export default Constants