import React from 'react'
import { Question as QuestionModel, QuestionType, ALL_QUESTION_TYPES } from '../../models/Form'
import { QuestionUpdated, QuestionMoved, QuestionRemoved, AnswerAdded  } from '../../logic/Form'
import { Answer } from './Answer'
import { EditPifContext } from './EditPifContext'
import { Button, Form, FormGroup, Input, Label, Row, Col, CustomInput } from 'reactstrap'

import './Question.scss'

export interface QuestionProps {
    question: QuestionModel
    index: number
    questionIds: Array<number>
    onToggle: (questionId: number) => void
}

export interface QuestionState {
}

type QuestionProperty = keyof QuestionModel | 'sortOrder'

export function QuestionCollapsed(props: QuestionProps) {
    return (
        <div className='question-collapsed border p-1' onClick={() => props.onToggle(props.question.questionId)}>
            {props.index + 1}. {props.question.questionTextEnglish}
        </div>
    )
}

export class Question extends React.Component<QuestionProps, QuestionState> {
    static contextType = EditPifContext
    context!: React.ContextType<typeof EditPifContext>

    constructor(props: QuestionProps) {
        super(props)
        this.state = {}
    }

    handleIndexChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        let op = new QuestionMoved(this.props.index, e.target.valueAsNumber - 1)
        this.context.updatePif(op)
    }

    handleQuestionDeleted = () => {
        let op = new QuestionRemoved(this.props.index)
        this.context.updatePif(op)
    } 

    handleAnswerAdded = () => {
        let op = new AnswerAdded(this.props.index)
        this.context.updatePif(op)
    } 

    updateQuestionWrapper = (questionUpdater: { (question: QuestionModel): QuestionModel }) => {
        let question = { ...this.props.question }

        let op = new QuestionUpdated(questionUpdater(question))

        this.context.updatePif(op)
    }

    handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.questionTextEnglish = e.target.value
            return question
        })
    }

    handleFrenchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.questionTextFrench = e.target.value
            return question
        })
    }


    handleQuestionTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.questionType = e.target.value as QuestionType

            if (question.questionType == "Checkbox") question.numberOfAnswers = -1
            else question.numberOfAnswers = 1
            return question
        })
    }

    handleNumberOfAnswersChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.numberOfAnswers = parseInt(e.target.value)
            return question
        })
    }

    handleScoreCapChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.scoreCap = parseFloat(e.target.value)
            return question
        })
    }

    handleMaxScoreOnlyChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.isMaxScoreOnly = e.target.checked
            return question
        })
    }

    handleIncludedOnReportChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.isIncludedOnReport = e.target.checked 
            return question
        })
    }

    handleIncludedInPortalChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateQuestionWrapper(question => {
            question.isIncludedInPortal = e.target.checked 
            return question
        })
    }

    private idFor = (property: QuestionProperty) => `question${this.props.question.questionId}_${property}`

    render() {
        let { question } = this.props

        let questionTypeRadioButtons = ALL_QUESTION_TYPES.map(qt => {
            return (
                <FormGroup check key={qt}>
                    <Label check>
                        <Input type='radio' name={this.idFor('questionType')} value={qt} checked={question.questionType == qt} onChange={this.handleQuestionTypeChanged} />
                        {qt}
                    </Label>
                </FormGroup>
            )
        })

        let numberOfAnswersEnabled: boolean = question.questionType == "Checkbox"

        let hasCustomNumberOfAnswers: boolean = ([1,-1].indexOf(question.numberOfAnswers) == -1)

        let numberOfAnswersFormGroup = (
            <FormGroup tag='fieldset'>
                <Label>Number of answers: </Label>
                <FormGroup check>
                    <Label check>
                        <Input type='radio' name={this.idFor('numberOfAnswers')} value={1} checked={question.numberOfAnswers == 1} onChange={this.handleNumberOfAnswersChanged} disabled={true} />
                        One
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type='radio' name={this.idFor('numberOfAnswers')} value={-1} checked={question.numberOfAnswers == -1} onChange={this.handleNumberOfAnswersChanged} disabled={!numberOfAnswersEnabled} />
                        Multiple
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type='radio' name={this.idFor('numberOfAnswers')} value={2} checked={hasCustomNumberOfAnswers} onChange={this.handleNumberOfAnswersChanged} disabled={!numberOfAnswersEnabled} />
                        Specify: <Input disabled={!hasCustomNumberOfAnswers} type='number' value={hasCustomNumberOfAnswers ? question.numberOfAnswers : ''} onChange={this.handleNumberOfAnswersChanged} min={2} />
                    </Label>
                </FormGroup>
            </FormGroup>
        )

        let answers = question.options.map((answer, ix) => <Answer key={answer.answerId} questionIds={this.props.questionIds} questionIndex={this.props.index} questionType={this.props.question.questionType} answer={answer} answerIndex={ix} totalAnswers={question.options.length} />)

        return (
            <div className='question-builder border mb-3 p-2' pif-questionid={this.props.question.questionId}>
                <Row>
                    <Col md={{ size: 9 }}>
                        <Form inline>
                            <FormGroup>
                                <Label for={this.idFor('sortOrder')} className='mr-2'>Question: </Label>
                                <Input id={this.idFor('sortOrder')} type='number' min={1} max={this.props.questionIds.length} value={this.props.index + 1} onChange={this.handleIndexChanged} style={{width: '50%'}} />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col>
                        <Button close onClick={this.handleQuestionDeleted} title='Delete Question' className='ml-3'></Button>
                        <Button close onClick={() => this.props.onToggle(this.props.question.questionId)} title='Minimize Question'>
                          <span aria-hidden>&ndash;</span>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 8 }}>
                        <FormGroup>
                            <Label for={this.idFor('questionTextEnglish')}>English Text: </Label>
                            <Input id={this.idFor('questionTextEnglish')} rows={4} type='textarea' value={question.questionTextEnglish} onChange={this.handleTextChanged} />
                        </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <FormGroup tag='fieldset'>
                            <Label>Question Type: </Label>
                            {questionTypeRadioButtons}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 8 }}>
                        <FormGroup>
                            <Label for={this.idFor('questionTextFrench')}>French Text: </Label>
                            <Input id={this.idFor('questionTextFrench')} rows={4} type='textarea' value={question.questionTextFrench} onChange={this.handleFrenchTextChanged} />
                        </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                        {numberOfAnswersFormGroup}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col md={{ size: 8 }}>
                        <CustomInput id={this.idFor('isMaxScoreOnly')} type='checkbox' name={this.idFor('isMaxScoreOnly')} checked={this.props.question.isMaxScoreOnly} onChange={this.handleMaxScoreOnlyChanged} label='Count only the highest scoring answer?' />
                        <CustomInput id={this.idFor('isIncludedInPortal')} type='checkbox' name={this.idFor('isIncludedInPortal')} checked={this.props.question.isIncludedInPortal} onChange={this.handleIncludedInPortalChanged} label='Include this question in the Portal?' />
                        <CustomInput id={this.idFor('isIncludedOnReport')} type='checkbox' name={this.idFor('isIncludedOnReport')} checked={this.props.question.isIncludedOnReport} onChange={this.handleIncludedOnReportChanged} label='Include this question on Soil PIF report?' />
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Form inline>
                            <FormGroup>
                                <Label for={this.idFor('scoreCap')} className='mr-2'>Score Cap: </Label>
                                <Input id={this.idFor('scoreCap')} type='number' min={0} value={this.props.question.scoreCap || ''} onChange={this.handleScoreCapChanged} style={{width: '30%'}} />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size:11, offset: 1 }} >
                        {answers}
                        <div className='text-right'>
                            <Button onClick={this.handleAnswerAdded}>Add Answer</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}