import React, { useContext } from 'react'
import { AppContext } from '../AppContext'

export const Marquee = () => {
    let context = useContext(AppContext)
    return (
        <div>
            <h1 className='text-center mt-5'>PIF Manager</h1>
            <h5 className='text-center'>Version 1.1</h5>
            <h3 className='text-center mb-5'>{context.settings && context.settings.environment}</h3>
        </div>
    )
}

export default Marquee
