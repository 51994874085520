import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export interface ConfirmModalProps {
    header?: string
    onDismiss: () => void
    onConfirm: () => void
}

export const ConfirmModal = (props: React.PropsWithChildren<ConfirmModalProps>) => (
    <Modal isOpen={true} toggle={props.onDismiss} size='xl'>
        <ModalHeader toggle={props.onDismiss}>{props.header || 'Confirm'}</ModalHeader>
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
            <Button onClick={props.onConfirm}>OK</Button>
            <Button onClick={props.onDismiss}>Cancel</Button>
        </ModalFooter>
    </Modal>
)

export default ConfirmModal
