import React from 'react'
import { StatelessComponent } from 'react'
import { Container } from 'reactstrap'
import { NavMenu } from './NavMenu'

export interface LayoutProps { fluidWidth?: boolean }

export const Layout: StatelessComponent<LayoutProps> = ({ fluidWidth = false, children }) => (
    <div>
        <NavMenu />
        <Container fluid={fluidWidth}>
            {children}
        </Container>
    </div>
)
