import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export interface AlertModalProps {
    header?: string
    message?: string
    onDismiss?: () => void,
}

export const AlertModal = (props: React.PropsWithChildren<AlertModalProps>) => {
    let message = props.message ? 
        <p>{props.message}</p> : 
        null

    return (
        <Modal isOpen={true} toggle={props.onDismiss} size='xl'>
            <ModalHeader toggle={props.onDismiss}>{props.header || 'Alert'}</ModalHeader>
            <ModalBody>
                {message}
                {props.children}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => props.onDismiss && props.onDismiss()}>OK</Button>
            </ModalFooter>
        </Modal>
    )
}

export default AlertModal
