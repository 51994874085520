import React, { ReactEventHandler } from 'react';
import { RouteChildrenProps } from 'react-router'
import { Button, Row, Col, ButtonGroup } from 'reactstrap'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import bsCustomFileInput from 'bs-custom-file-input'

import Constants, { PIF_FORM_TYPE, SYSTEMS_BONUS_FORM_TYPE } from '../Constants'
import { assertNever } from '../utility'
import { json } from '../http'

import AppContext, { AppContextType } from '../AppContext'
import { getHierarchyOptions, getHierarchyItem, HierarchyCoords, HierarchyItem, FormType } from '../models/Hierarchy'
import { FormItem, getAssignments } from '../models/FormItem'
import { InputChangedHandler, Option } from '../components/input'
import { ImportFormsReport } from '../components/ImportFormsReport'
import { ImportResponsesReport } from '../components/ImportResponsesReport'
import { DeploymentReport } from '../components/DeploymentReport'
import { DropDown } from '../components/DropDown';
import { ListBox } from '../components/ListBox';
import { BmpTable } from '../components/BmpTable';
import ConfirmModal from '../components/modals/ConfirmModal';
import AlertModal from '../components/modals/AlertModal';
import Marquee from '../components/Marquee'

import './SelectPif.scss'
import { getFormScores } from '../models/Form';

// TODO: Refactor this modal signal system into something more compact 
// that takes into account the fact that we have 3 processes (import-forms, import-responses, deploy-forms)
// that each have 3 states: confirm, in-progress, complete

interface ImportFormsConfirmModalSignal {
    type: 'import-forms-confirm'
}

interface ImportFormsInProgressModalSignal {
    type: 'import-forms-in-progress'
}

interface ImportFormsCompleteModalSignal {
    type: 'import-forms-complete'
    report: JSX.Element
}

interface ImportResponsesConfirmModalSignal {
    type: 'import-responses-confirm'
}

interface ImportResponsesInProgressModalSignal {
    type: 'import-responses-in-progress'
}

interface ImportResponsesCompleteModalSignal {
    type: 'import-responses-complete'
    report: JSX.Element
}

interface DeploymentDestinationSwitch {
    CAP: boolean
    DIRT: boolean
}

type DeploymentDestination = keyof DeploymentDestinationSwitch

const ALL_DEPLOYMENT_DESTINATIONS: Array<DeploymentDestination> = ['CAP', 'DIRT']

interface DeployFormsConfirmModalSignal {
    type: 'deployment-confirm'
    destinations: Array<DeploymentDestination>
}

interface DeployFormsInProgressModalSignal {
    type: 'deployment-in-progress'
    destinations: Array<DeploymentDestination>
}

interface DeployFormsCompleteModalSignal {
    type: 'deployment-complete'
    report: JSX.Element
}

export interface SelectPifState extends HierarchyCoords {
    pifItems: Array<FormItem>, 

    // TODO: Refactor this modal signal system into something more compact 
    // that takes into account the fact that we have 3 processes (import-forms, import-responses, deploy-forms)
    // that each have 3 states: confirm, in-progress, complete
    openedModal?:
        ImportFormsConfirmModalSignal | ImportFormsInProgressModalSignal | ImportFormsCompleteModalSignal |
        ImportResponsesConfirmModalSignal | ImportResponsesInProgressModalSignal | ImportResponsesCompleteModalSignal |
        DeployFormsConfirmModalSignal | DeployFormsInProgressModalSignal | DeployFormsCompleteModalSignal

    exportIds?: boolean
}

interface DerivedState {
    buttonEnabled: {
        create: boolean
        importDefinitions: boolean
        importResponses: boolean
        exportDefinitions: boolean
        downloadAnswersTemplate: boolean
        review: boolean 
        deploy: DeploymentDestinationSwitch
        dataStewardPreview: boolean
        dataStewardSection:boolean
    }, 
    buttonText: {
        importDefinitions: string
        exportDefinitions: string
        downloadAnswersTemplate: string
        deploy: string
    },
    confirmModalText: {
        importConfirmation: string, 
        deploymentConfirmation: string, 
    }
}

class SessionStorage {
    private static _hierarchyCoordsKey = 'hierarchyCoords'

    static hierarchyCoords(coords?: HierarchyCoords): HierarchyCoords | undefined {
        if (coords) { // act as setter
            sessionStorage.setItem(SessionStorage._hierarchyCoordsKey, JSON.stringify(coords))
        }
        else { // act as getter
            let coordsString = sessionStorage.getItem(SessionStorage._hierarchyCoordsKey)
            return coordsString && JSON.parse(coordsString)
        }
    }
}


function canDeploy(context: AppContextType, state: SelectPifState, destination: DeploymentDestination): boolean {
    if (state.pifId) {
        // for pif to be deployable, it has to be associated to some bmp which exists in the correct destination (dirt or cap)
        let pif = state.pifItems.find(pif => pif.formId == state.pifId)
        if (!pif) return false

        let bmps: Array<HierarchyItem> = !state.formType ? [] :
            getAssignments(pif, state.formType)
                .map(a => getHierarchyItem(context.bmps, a.bmpKey)!)
                .filter(b => !!b);

        switch (destination) {
            case 'CAP': return bmps.some(b => b.isPortal) 
            case 'DIRT': return bmps.some(b => b.isDirt)
            default: assertNever(destination)
        }
    }
    else if (state.intakeKey) {
        // for intake to be deployable it has to exist in the correct destination (dirt or cap)
        let intake = getHierarchyItem(context.intakes, state.intakeKey)
        if (!intake) return false
        if (!state.formType) return false

        let pifItems = state.pifItems.filter(p => getAssignments(p, state.formType!).some(a => a.intakeKey == state.intakeKey))
        if (pifItems.length == 0) {
            return false
        }

        switch (destination) {
            case 'CAP': return intake.isPortal
            case 'DIRT': return intake.isDirt
            default: assertNever(destination)
        }
    }

    return false
}

type SelectionLevel = 'Form' | 'Intake'

function getDerivedState(context: AppContextType, state: SelectPifState): DerivedState {
    let selectionLevel: SelectionLevel = state.pifId ? 'Form' : 'Intake'
    let deploymentDestination = (state.openedModal && state.openedModal.type == 'deployment-confirm') && state.openedModal.destinations.join(' and ')

    let derivedState: DerivedState = {
        buttonEnabled: {
            create: !!state.intakeKey && !!state.formType, 
            importDefinitions: !!state.intakeKey && !!state.formType,
            importResponses: !!state.pifId && !!state.formType,
            exportDefinitions: !!state.intakeKey && !!state.formType,
            downloadAnswersTemplate: !!state.intakeKey && !!state.formType,
            review: !!state.pifId,
            deploy: {
                CAP: canDeploy(context, state, 'CAP') && !!state.formType && state.formType == 'pif', 
                DIRT: canDeploy(context, state, 'DIRT') && !!state.formType
            },
            dataStewardSection: !!state.formType && state.formType == 'pif',
            dataStewardPreview: !!state.intakeKey && !state.pifId && !!state.formType && state.formType == 'pif' 
        }, 
        buttonText: {
            importDefinitions: `Import ${selectionLevel} from Excel`, 
            exportDefinitions: `Export ${selectionLevel} to Excel`, 
            downloadAnswersTemplate: `Download ${selectionLevel}'s Excel Answers Template` + (selectionLevel == 'Intake' && 's' || '' ), 
            deploy: `Deploy ${selectionLevel}`
        }, 
        confirmModalText: {
            importConfirmation: state.pifId ? 
                'Existing form will be lost prior to import.  Continue?' : 
                'All existing forms in this intake will be lost prior to import.  Continue?' , 
            deploymentConfirmation: state.pifId ?
                `Existing form in ${deploymentDestination} will be lost prior to deployment.  Continue?` : 
                `All existing forms in this intake in ${deploymentDestination} will be lost prior to deployment.  Continue?` , 
        }
    }

    return derivedState
}

export class SelectPif extends React.Component<RouteChildrenProps, SelectPifState> {
    static displayName = SelectPif.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: RouteChildrenProps) {
        super(props);
        this.state = { pifItems: [], ...SessionStorage.hierarchyCoords() }
    }

    async componentDidMount() {
        this.context.setFluidWidth(false)

        bsCustomFileInput.init()

        this.loadPifItems()
    }

    loadPifItems = async () => {
        let pifItems = await json.get(Constants.Paths.Api.ProjectInformationForms.All)

        let pifId = this.state.pifId

        if (!pifItems.some(pi => pi.formId == pifId)) pifId = undefined

        this.setState({ pifItems, pifId })
    }

    componentWillUnmount() {
        let { formType, applicationTypeName, intakeKey, pifId } = this.state
        SessionStorage.hierarchyCoords({ formType, applicationTypeName, intakeKey, pifId })
        bsCustomFileInput.destroy()
    }

    private getPifs(): Array<FormItem> {
        if (this.state.formType) {
            return this.state.pifItems
                .filter(pi =>
                    getAssignments(pi, this.state.formType!)
                        .some(d => d.intakeKey == this.state.intakeKey)
                )
        }
        else return [];
    }

    private getPifOptions(): Array<Option> {
        return this.getPifs()
            .map(pif => {
                return { text: pif.name, value: pif.formId }
            })
    }

    private getPifListBox(): React.ReactElement | null {
        let pifListBox: React.ReactElement | null = null

        let pifClearButton: React.ReactElement | null = null

        if (this.state.intakeKey) {
            let pifOptions = this.getPifOptions()

            if (this.state.pifId) pifClearButton = <Button className='float-right' outline onClick={() => this.pifChanged('')} title='Select Parent Intake'><span className='font-weight-bold'>&#10531;</span></Button>

            if (pifOptions.length > 0) pifListBox = (
                <div>
                    These Forms are in use for this Intake: {pifClearButton}
                    <ListBox options={pifOptions} onChange={this.pifChanged} value={this.state.pifId} />
                </div>
            )
            else pifListBox = (
                <div className='text-center'>
                    No Forms Defined
                </div>
            )
        }

        return pifListBox
    }

    private getBmpTable(): React.ReactElement | undefined {
        let pif = this.state.pifItems.find(pif => pif.formId == this.state.pifId)

        if (pif && this.state.formType) {
            return (
                <div>
                    This Form is assigned to these BMPs:
                    <BmpTable deployments={getAssignments(pif, this.state.formType)} projectCategories={this.context.bmps!} intakes={this.context.intakes!} />
                </div>
            )
        }
    }
    formTypeChanged: InputChangedHandler = (val: string) =>
        this.setState({ formType:val as FormType, applicationTypeName: undefined, intakeKey: undefined, pifId: undefined })

    applicationTypeChanged: InputChangedHandler = (val: string) =>
        this.setState({ applicationTypeName: val, intakeKey: undefined, pifId: undefined })

    intakeChanged: InputChangedHandler = (val: string) =>
        this.setState({ intakeKey: val, pifId: undefined })

    pifChanged: InputChangedHandler = (val: string) => 
        this.setState({ pifId: parseInt(val) || undefined })

    editClicked = () => this.state.pifId && this.props.history.push(Constants.Paths.Web.editPif(this.state.pifId))

    createClicked = () => this.state.intakeKey && this.props.history.push(Constants.Paths.Web.createPif(this.state.intakeKey, this.state.formType))

    importFormsFileSelected = (e: React.SyntheticEvent<HTMLInputElement>) => {
        let fileInput: any = e.target
        if (fileInput.files && fileInput.files.length > 0) {
            this.setState({ openedModal: { type: "import-forms-confirm" } })
        }
    }

    importResponsesFileSelected = (e: React.SyntheticEvent<HTMLInputElement>) => {
        let fileInput: any = e.target
        if (fileInput.files && fileInput.files.length > 0) {
            this.setState({ openedModal: { type: "import-responses-confirm" } })
        }
    }

    importFormsConfirmed = () => {
        this.setState({ openedModal: { type: "import-forms-in-progress" } }, async () => {
            let data = new FormData(document.getElementById('frmImportForms') as HTMLFormElement)

            let importResult = await json.post(Constants.Paths.Api.ProjectInformationForms.ImportDefinitions, { data, id: this.state.formType })

            if (importResult) {
                let report = <ImportFormsReport importResult={importResult} wasSinglePifReplaced={!!this.state.pifId} /> 

                this.setState({ openedModal: { type: 'import-forms-complete', report } }, () => {
                    this.loadPifItems()
                })
            }
        })
    }

    importResponsesConfirmed = () => {
        this.setState({ openedModal: { type: "import-responses-in-progress" } }, async () => {
            let data = new FormData(document.getElementById('frmImportResponses') as HTMLFormElement)

            let importResult = await json.post(Constants.Paths.Api.ProjectInformationForms.ImportResponses, { data, id: this.state.formType })

            if (importResult) {
                let report = <ImportResponsesReport importResult={importResult} /> 

                this.setState({ openedModal: { type: 'import-responses-complete', report } }, () => {
                    this.loadPifItems()
                })
            }
        })
    }

    deploymentSelected = (destinations: Array<DeploymentDestination>) => {
        this.setState({ openedModal: { type: "deployment-confirm", destinations } })
    }

    getDeploymentConfirmedHandler = (destinations: Array<DeploymentDestination>) => {
        return () => {
            let { intakeKey, pifId: projectInformationFormId } = this.state

            this.setState({ openedModal: { type: "deployment-in-progress", destinations } }, async () => {

                let data = { intakeKey, projectInformationFormId, destinations }

                let deploymentResult = await json.post(Constants.Paths.Api.ProjectInformationForms.Deploy, { data, id: this.state.formType })

                if (deploymentResult) {
                    let report = <DeploymentReport deploymentResult={deploymentResult} /> 

                    this.setState({ openedModal: { type: 'deployment-complete', report } }, () => {
                        this.loadPifItems()
                    })
                }

            })
        }
    }

    exportClicked = async () => {
        let { intakeKey, pifId: projectInformationFormId, exportIds, formType } = this.state

        try {
            let fileResult = await json.getFile(Constants.Paths.Api.ProjectInformationForms.Export, { data: { intakeKey, projectInformationFormId, exportIds,formType } })

            if (!fileResult) alert("Download Error")
            else {
                var blob = new Blob([fileResult.fileContents])
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileResult.fileName
                link.click()
                link.remove()
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    downloadAnswersTemplateClicked = async () => {
        let { intakeKey, pifId: projectInformationFormId, formType } = this.state

        try {
            let fileResult = await json.getFile(Constants.Paths.Api.ProjectInformationForms.ExcelAnswersTemplate, { data: { intakeKey, projectInformationFormId, formType } })

            if (!fileResult) alert("Download Error")
            else {
                var blob = new Blob([fileResult.fileContents])
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileResult.fileName
                link.click()
                link.remove()
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    exportIdsToggled = () => {
        let { exportIds } = this.state
        this.setState({ exportIds: !exportIds })
    }

    dataStewardPreviewClicked = async () => {
        let { intakeKey } = this.state

        try {
            let fileResult = await json.getFile(Constants.Paths.Api.ProjectInformationForms.DataStewardPreview, { id: intakeKey })

            if (!fileResult) alert("Download Error")
            else {
                var blob = new Blob([fileResult.fileContents])
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileResult.fileName
                link.click()
                link.remove()
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    dataStewardActiveClicked = async () => {

        try {
            let fileResult = await json.getFile(Constants.Paths.Api.ProjectInformationForms.DataStewardActive)

            if (!fileResult) alert("Download Error")
            else {
                var blob = new Blob([fileResult.fileContents])
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileResult.fileName
                link.click()
                link.remove()
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    confirmCancelled = () => {
        this.setState({ openedModal: undefined })
    }

    render() {
        let derivedState = getDerivedState(this.context, this.state)

        let modal = null
        if (this.state.openedModal) {

            // TODO: Refactor this modal signal system into something more compact 
            // that takes into account the fact that we have 3 processes (import-forms, import-responses, deploy-forms)
            // that each have 3 states: confirm, in-progress, complete
            switch (this.state.openedModal.type) {
                case 'import-forms-confirm':
                    modal = (
                        <ConfirmModal header='Confirm Import' onDismiss={this.confirmCancelled} onConfirm={this.importFormsConfirmed}>
                            {derivedState.confirmModalText.importConfirmation}
                        </ConfirmModal>
                    )
                    break
                case 'import-forms-in-progress':
                    modal = <AlertModal header='Import Progress' message='Import of Forms is in progress.  Please Wait.' onDismiss={() => this.setState({ openedModal: undefined })} />
                    break
                case 'import-forms-complete':
                    modal = (
                        <AlertModal header='Import Progress' message='Importing Forms is Complete' onDismiss={() => this.setState({ openedModal: undefined })}>
                            {this.state.openedModal.report}
                        </AlertModal>
                    )
                    break


                case 'import-responses-confirm':
                    modal = (
                        <ConfirmModal header='Confirm Import' onDismiss={this.confirmCancelled} onConfirm={this.importResponsesConfirmed}>
                            Existing responses for the applications in the excel file will be lost prior to import.  Continue?
                        </ConfirmModal>
                    )
                    break
                case 'import-responses-in-progress':
                    modal = <AlertModal header='Import Progress' message='Import of Responses is in progress.  Please Wait.' onDismiss={() => this.setState({ openedModal: undefined })} />
                    break
                case 'import-responses-complete':
                    modal = (
                        <AlertModal header='Import Progress' message='Importing Responses is Complete' onDismiss={() => this.setState({ openedModal: undefined })}>
                            {this.state.openedModal.report}
                        </AlertModal>
                    )
                    break

                case 'deployment-confirm': 
                    modal = (
                        <ConfirmModal header='Confirm Deployment' onDismiss={this.confirmCancelled} onConfirm={this.getDeploymentConfirmedHandler(this.state.openedModal.destinations)}>
                            {derivedState.confirmModalText.deploymentConfirmation}
                        </ConfirmModal>
                    )
                    break
                case 'deployment-in-progress':
                    modal = <AlertModal header='Deployment Progress' message='Deployment is in progress.  Please Wait.' onDismiss={() => this.setState({ openedModal: undefined })} />
                    break
                case 'deployment-complete':
                    modal = (
                        <AlertModal header='Deployment Progress' message='Deployment Complete' onDismiss={() => this.setState({ openedModal: undefined })}>
                            {this.state.openedModal.report}
                        </AlertModal>
                    )
                    break
                default: return assertNever(this.state.openedModal)
            }
        }

        let deploymentDropdownItems = ALL_DEPLOYMENT_DESTINATIONS.map((destination: DeploymentDestination) => {
            return (
                <DropdownItem key={destination} onClick={() => this.deploymentSelected([destination])} disabled={!derivedState.buttonEnabled.deploy[destination]}>
                    To {destination}
                </DropdownItem>
            )
        })

        deploymentDropdownItems.push(
            <DropdownItem key={'BOTH'} onClick={() => this.deploymentSelected(ALL_DEPLOYMENT_DESTINATIONS)} disabled={!derivedState.buttonEnabled.deploy.CAP || !derivedState.buttonEnabled.deploy.DIRT}>
                To BOTH
            </DropdownItem>
        )

        return (
            <div>
                {modal}
                <Marquee />
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 8 }}>
                        <DropDown
                            label='Form Type: '
                            options={getHierarchyOptions(this.context.formTypes!)}
                            value={this.state.formType}
                            onChange={this.formTypeChanged}
                            defaultText='-- SELECT --'
                        />
                        <DropDown
                            label='Application Type: '
                            options={getHierarchyOptions(this.context.applicationTypes!)}
                            value={this.state.applicationTypeName}
                            onChange={this.applicationTypeChanged}
                            defaultText='-- SELECT --'
                        />
                        <DropDown
                            label='Intake: '
                            options={getHierarchyOptions(this.context.intakes!, this.state.applicationTypeName)}
                            value={this.state.intakeKey}
                            onChange={this.intakeChanged}
                            defaultText='-- SELECT --'
                        />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 8 }}>
                        {this.getPifListBox()}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 8 }}>
                        {this.getBmpTable()}
                    </Col>
                </Row>

                <div className='mb-3 text-center'>What would you like to do?</div>

                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 4 }}>
                        <form id='frmImportForms'>
                            <input type='hidden' name='intakeKey' value={this.state.intakeKey || ''} />
                            <input type='hidden' name='projectInformationFormId' value={this.state.pifId || ''} />
                            <div className='custom-file'>
                                <input name='excelFile' type='file' className='custom-file-input' disabled={!derivedState.buttonEnabled.importDefinitions} onChange={this.importFormsFileSelected} />
                                <label className='custom-file-label' htmlFor='excelFormsFile'>{derivedState.buttonText.importDefinitions}</label>
                            </div>
                        </form>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Button block outline disabled={!derivedState.buttonEnabled.review} onClick={this.editClicked}>Review/Edit the Form</Button>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 4 }}>
                        <ButtonGroup className='btn-block'>
                            <Button outline active={this.state.exportIds} onClick={this.exportIdsToggled}>IDs <span className='ballot-box'></span></Button>
                            <Button outline disabled={!derivedState.buttonEnabled.exportDefinitions} onClick={this.exportClicked}>{derivedState.buttonText.exportDefinitions}</Button>
                        </ButtonGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <UncontrolledDropdown direction='right'>
                            <DropdownToggle block outline caret disabled={!derivedState.buttonEnabled.deploy.CAP && !derivedState.buttonEnabled.deploy.DIRT}>
                                {derivedState.buttonText.deploy}
                            </DropdownToggle>
                            <DropdownMenu>
                                {deploymentDropdownItems}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 4 }}>
                        <UncontrolledDropdown direction='right'>
                            <DropdownToggle block outline caret disabled={!derivedState.buttonEnabled.dataStewardSection}>
                                Data Steward Download
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={this.dataStewardPreviewClicked} disabled={!derivedState.buttonEnabled.dataStewardPreview}>
                                    Preview
                                </DropdownItem>
                                <DropdownItem onClick={this.dataStewardActiveClicked}>
                                    Active
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Button block outline disabled={!derivedState.buttonEnabled.create} onClick={this.createClicked}>Create a new Form</Button>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={{ offset: 2, size: 4 }}>
                        <Button block outline disabled={!derivedState.buttonEnabled.downloadAnswersTemplate} onClick={this.downloadAnswersTemplateClicked}>
                            {derivedState.buttonText.downloadAnswersTemplate}
                        </Button>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <form id='frmImportResponses'>
                            <input type='hidden' name='projectInformationFormId' value={this.state.pifId || ''} />
                            <div className='custom-file'>
                                <input name='excelFile' type='file' className='custom-file-input' disabled={!derivedState.buttonEnabled.importResponses} onChange={this.importResponsesFileSelected} />
                                <label className='custom-file-label' htmlFor='excelResponsesFile'>Import Responses from Excel</label>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}
