import { Question, Option, Form } from '../models/Form'

function defaultQuestion(): Question {
    return {
        questionId: 0,
        questionType: "Checkbox", 
        questionTextEnglish: '', 
        questionTextFrench: '',
        numberOfAnswers: -1,
        options: [], 
        isMaxScoreOnly: false,
        isIncludedInPortal: true,
        isIncludedOnReport: true 
    }
}

function defaultAnswer(): Option {
    return {
        answerId: 0,
        answerTextEnglish: '',
        answerTextFrench: '',
        isDescriptionRequired: false,
        isAllOfTheAbove: false,
        isNoneOfTheAbove: false
    }
}

function newQuestionId(pif: Form): number {
    let minQuestionId = pif.questions.reduce((minQuestionId, question) => {
        return question.questionId < minQuestionId ?
            question.questionId :
            minQuestionId
    }, 0)
    return minQuestionId - 1
}

function newAnswerId(pif: Form): number {
    let minAnswerId = pif.questions.reduce((formsMinAnswerId, question) => {
        let newMinAnswerId = question.options.reduce((minAnswerId, option) => {
            return option.answerId < minAnswerId ?
                option.answerId :
                minAnswerId
        }, formsMinAnswerId)
        return newMinAnswerId
    }, 0)
    return minAnswerId - 1
}

export function updateName(pif: Form, name: string) {
    let newPif = { ...pif, name }

    return newPif
}

export function updateBaseScore(pif: Form, baseScore: number) {
    let newPif = { ...pif, baseScore }

    return newPif
}

export function addNewQuestion(pif: Form): Form {
    let newPif = { ...pif }

    let newQuestion = defaultQuestion()
    newQuestion.questionId = newQuestionId(pif)

    newPif.questions = newPif.questions.slice()
    newPif.questions.push(newQuestion)

    return newPif
}

export function removeQuestion(pif: Form, questionIndex: number): Form {
    let newPif = { ...pif }

    newPif.questions = newPif.questions.slice()
    newPif.questions.splice(questionIndex, 1)

    return newPif
}

export function addNewAnswer(pif: Form, questionIndex: number): Form {
    let newPif = { ...pif }

    let newAnswer = defaultAnswer()
    newAnswer.answerId = newAnswerId(pif)

    let newQuestion = { ...pif.questions[questionIndex] }
    newQuestion.options = newQuestion.options.slice()
    newQuestion.options.push(newAnswer)

    newPif.questions = newPif.questions.slice()
    newPif.questions[questionIndex] = newQuestion

    return newPif
}

export function removeAnswer(pif: Form, questionIndex: number, answerIndex: number): Form {
    let newPif = { ...pif }

    let newQuestion = { ...pif.questions[questionIndex] }
    newQuestion.options = newQuestion.options.slice()
    newQuestion.options.splice(answerIndex, 1)

    newPif.questions = pif.questions.slice()
    newPif.questions[questionIndex] = newQuestion

    return newPif
}

export function updateQuestion(pif: Form, updatedQuestion: Question) {
    let newPif = { ...pif }
    newPif.questions = newPif.questions.slice()

    let index = newPif.questions.findIndex(q => q.questionId == updatedQuestion.questionId)
    newPif.questions[index] = updatedQuestion

    return newPif
}

export function moveQuestion(pif: Form, originalIndex: number, updatedIndex: number) {
    let newPif = { ...pif }
    newPif.questions = newPif.questions.slice()

    let [question] = newPif.questions.splice(originalIndex, 1)
    newPif.questions.splice(updatedIndex, 0, question)

    return newPif
}

export function updateAnswer(pif: Form, updatedAnswer: Option) {
    let newPif = { ...pif }
    newPif.questions = pif.questions.slice()

    let answerIndex = -1
    let questionIndex = pif.questions.findIndex(question => {
        let foundAnswerIndex = question.options.findIndex(o => o.answerId == updatedAnswer.answerId)

        if (foundAnswerIndex >= 0) {
            answerIndex = foundAnswerIndex
            return true
        }
    })

    newPif.questions[questionIndex] = { ...pif.questions[questionIndex] }
    newPif.questions[questionIndex].options = pif.questions[questionIndex].options.slice()
    newPif.questions[questionIndex].options[answerIndex] = updatedAnswer 

    return newPif
}

export function moveAnswer(pif: Form, questionIndex: number, originalIndex: number, updatedIndex: number) {
    let newPif = { ...pif }
    newPif.questions = pif.questions.slice()

    newPif.questions[questionIndex] = { ...pif.questions[questionIndex] }
    newPif.questions[questionIndex].options = pif.questions[questionIndex].options.slice()

    let [answer] = newPif.questions[questionIndex].options.splice(originalIndex, 1)
    newPif.questions[questionIndex].options.splice(updatedIndex, 0, answer)

    return newPif
}

export abstract class PifUpdateOperation {
}

export class NameUpdated extends PifUpdateOperation {
    constructor(public name: string) { super() }
}

export class BaseScoreUpdated extends PifUpdateOperation {
    constructor(public baseScore: number) { super() }
}

export class QuestionAdded extends PifUpdateOperation {
}

export class QuestionRemoved extends PifUpdateOperation {
    constructor(public questionIndex: number) { super() }
}

export class QuestionUpdated extends PifUpdateOperation {
    constructor(public updatedQuestion: Question) { super() }
}

export class QuestionMoved extends PifUpdateOperation {
    constructor(public originalIndex: number, public updatedIndex: number) { super() }
} 

export class AnswerAdded extends PifUpdateOperation {
    constructor(public questionIndex: number) { super() }
}

export class AnswerRemoved extends PifUpdateOperation {
    constructor(public questionIndex: number, public answerIndex: number) { super() }
} 

export class AnswerUpdated extends PifUpdateOperation { 
    constructor(public updatedAnswer: Option) { super() }
} 

export class AnswerMoved extends PifUpdateOperation { 
    constructor(public questionIndex: number, public originalIndex: number, public updatedIndex: number) { super() }
}

export function updatePif(pif: Form, op: PifUpdateOperation): Form {
    if (op instanceof NameUpdated) return updateName(pif, op.name);
    else if (op instanceof BaseScoreUpdated) return updateBaseScore(pif, op.baseScore);
    else if (op instanceof QuestionAdded) return addNewQuestion(pif);
    else if (op instanceof QuestionUpdated) return updateQuestion(pif, op.updatedQuestion)
    else if (op instanceof QuestionMoved) return moveQuestion(pif, op.originalIndex, op.updatedIndex)
    else if (op instanceof QuestionRemoved) return removeQuestion(pif, op.questionIndex)
    else if (op instanceof AnswerAdded) return addNewAnswer(pif, op.questionIndex)
    else if (op instanceof AnswerUpdated) return updateAnswer(pif, op.updatedAnswer)
    else if (op instanceof AnswerMoved) return moveAnswer(pif, op.questionIndex, op.originalIndex, op.updatedIndex)
    else if (op instanceof AnswerRemoved) return removeAnswer(pif, op.questionIndex, op.answerIndex)
    else throw 'Operation not supported'
}

let answerComparableKeys: Array<keyof Option> = [
    'answerId',
    'answerTextEnglish',
    'answerTextFrench',
    'isAllOfTheAbove',
    'isDescriptionRequired',
    'isNoneOfTheAbove',
    'nextQuestionId',
    'score',
];

function answersEqual(ans1: Option, ans2: Option): boolean {
    return !answerComparableKeys.some(key => ans1[key] != ans2[key]);
}

let questionComparableKeys: Array<keyof Question> = [
    'isIncludedOnReport',
    'isMaxScoreOnly',
    'isIncludedInPortal',
    'numberOfAnswers',
    'questionId',
    'questionTextEnglish',
    'questionTextFrench',
    'scoreCap',
    'questionType',
];

function questionsEqual(q1: Question, q2: Question): boolean {
    return !questionComparableKeys.some(key => q1[key] != q2[key]) &&
        q1.options.length == q2.options.length &&
        !q1.options.some((ans1, ix) => !answersEqual(ans1, q2.options[ix]))
}

let pifComparableKeys: Array<keyof Form> = [
    'name',
    'baseScore',
];

export function pifsEqual(pif1: Form, pif2: Form): boolean {
    return !pifComparableKeys.some(key => pif1[key] != pif2[key]) &&
        pif1.questions.length == pif2.questions.length &&
        !pif1.questions.some((q1, ix) => !questionsEqual(q1, pif2.questions[ix]))
}
