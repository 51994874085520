import React, { ReactElement } from 'react';
import { Button, Row, Col, Form as ReactStrapForm, FormGroup, Label, Input, Alert, Container } from 'reactstrap'
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import Split from 'split.js'

import Constants, { PIF_FORM_TYPE, SYSTEMS_BONUS_FORM_TYPE } from '../Constants'
import { json } from '../http'

import Loading from '../components/Loading'
import BmpTable from '../components/BmpTable'
import AssignModal from '../components/EditPif/AssignModal'
import AlertModal from '../components/modals/AlertModal'

import { Form, getFormScores, Responses } from '../models/Form'
import { Assignment } from '../models/FormItem'
import { FormType } from '../models/Hierarchy'
import { PifUpdateOperation, updatePif, pifsEqual } from '../logic/Form'
import { updateName, updateBaseScore, addNewQuestion } from '../logic/Form'
import { Question, QuestionCollapsed } from '../components/EditPif/Question'
import { EditPifContext, PifUpdatedHandler } from '../components/EditPif/EditPifContext'
import { assertNever } from '../utility'
import AppContext from '../AppContext'
import ConfirmModal from '../components/modals/ConfirmModal';

import './EditPif.scss'

export interface EditPifRoute {
    pifId: string
}

export interface EditPifProps extends RouteComponentProps<EditPifRoute> { }

interface AssignModalSignal {
    type: 'assign'
}

interface AlertModalSignal {
    type: 'alert'
    message: string
}

interface ValidationModalSignal {
    type: 'validation'
    isValid: boolean
}

interface DeleteModalSignal {
    type: 'delete'
}

type Language = 'English' | 'French'

export interface EditPifState {
    originalPif?: Form
    currentPif?: Form

    // TODO: refactor assignments into keyed object
    allAssignmentsPIF: Array<Assignment>,
    allAssignmentsSystemBonus: Array<Assignment>,

    updateRenderedFormTimeoutId?: number
    updateRenderedScoreTimeoutId?: number
    resizeIsListened?: boolean
    openedModal?: AssignModalSignal | AlertModalSignal | ValidationModalSignal | DeleteModalSignal
    score: number
    language: Language
    selectedQuestionId?: number,

    // TODO: change type to 'FormType'
    formType?: string
}

export class EditPif extends React.Component<EditPifProps, EditPifState> {
    static displayName = EditPif.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    static UPDATE_FORM_TIMEOUT_INTERVAL = 1000
    static UPDATE_SCORE_TIMEOUT_INTERVAL = 200 
    static RENDERED_PIF_DIV_ID = 'rendered-pif'

    isLoading = () => !this.state.currentPif || !this.state.allAssignmentsPIF || !this.state.allAssignmentsSystemBonus

    formPreviewRef: React.RefObject<HTMLDivElement>

    constructor(props: EditPifProps) {
        super(props)
        this.formPreviewRef = React.createRef<HTMLDivElement>()
        this.state = { allAssignmentsPIF: [], allAssignmentsSystemBonus: [],score: 0, language: 'English' }
    }

    pifId(): number {
        return parseInt(this.props.match.params.pifId)
    }

    assignments(): Array<Assignment> {
        let pifId = this.pifId()
        var ret = this.state.allAssignmentsPIF.filter(d => d.formId == pifId)
        if (ret.length < 1) {
            ret = this.state.allAssignmentsSystemBonus.filter(d => d.formId == pifId)
        }
        return ret;
    }

    formTypeKey(): FormType | undefined {
        let pifId = this.pifId()
        if (this.state.allAssignmentsPIF.some(d => d.formId == pifId)) {
            return PIF_FORM_TYPE;
        }
        if (this.state.allAssignmentsSystemBonus.some(d => d.formId == pifId)) {
            return SYSTEMS_BONUS_FORM_TYPE;
        }
    }

    formType(): string {
        let pifId = this.pifId()
        if (this.state.allAssignmentsPIF.some(d => d.formId == pifId)) {
            return this.context.formTypes!.find(i => i.id == PIF_FORM_TYPE)!.name;
        }
        if (this.state.allAssignmentsSystemBonus.some(d => d.formId == pifId)) {
            return this.context.formTypes!.find(i => i.id == SYSTEMS_BONUS_FORM_TYPE)!.name;
        }

        return "";
    }

    loadPif = async () => {
        let [originalPif, allAssignmentsPIF, allAssignmentsSystemBonus] = await Promise.all([
            json.get(Constants.Paths.Api.ProjectInformationForms.One, { id: this.pifId() }),
            json.get(Constants.Paths.Api.ProjectInformationForms.Assignments, { id: PIF_FORM_TYPE }),
            json.get(Constants.Paths.Api.ProjectInformationForms.Assignments, { id: SYSTEMS_BONUS_FORM_TYPE }),
        ])

        let currentPif = originalPif

        this.setState({ originalPif, currentPif, allAssignmentsPIF, allAssignmentsSystemBonus }, this.updateRenderedPif)
    }

    async componentDidMount() {
        this.context.setFluidWidth()
        this.loadPif()
    }

    componentDidUpdate(prevProps: EditPifProps) {
        // so that we can maintain correct dual scroll
        if (!this.state.resizeIsListened && this.formPreviewRef.current) {
            window.addEventListener('resize', this.resizePreview)

            Split(['#form-builder', '#form-preview'])
            this.resizePreview()
            this.setState({resizeIsListened: true})
        }

        // url changes after a successful copy operation
        if (prevProps.match.params.pifId != this.props.match.params.pifId) {
            this.loadPif()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePreview)

        // destroy pifViewer if one exists
        this.$formTarget.pifViewer('instance') && this.$formTarget.pifViewer('destroy')
    }

    // so that we can maintain correct dual scroll
    resizePreview = () => {
        if (this.formPreviewRef.current) {
            let screenHeight = window.innerHeight;

            this.formPreviewRef.current.style!.height = `${screenHeight - 75}px`

            this.$gutter.height(this.$gutter.parent().height())
        }
    }

    // we delay updates so as not to inundate event loop in the case of rapid-fire input
    updatePifWrapper = (pifUpdater: { (oldPif: Form): Form }) => {
        let { currentPif, updateRenderedFormTimeoutId } = this.state
        clearTimeout(updateRenderedFormTimeoutId)

        let updatedPif = pifUpdater(currentPif!)

        updateRenderedFormTimeoutId = window.setTimeout(this.updateRenderedPif, EditPif.UPDATE_FORM_TIMEOUT_INTERVAL)

        this.setState({currentPif: updatedPif, updateRenderedFormTimeoutId})
    }

    // convenience accessor for global jQuery
    get global$() { return (window as any).$ }

    // convenience accessor for pifViewer target
    get $formTarget() { return this.global$('#' + EditPif.RENDERED_PIF_DIV_ID) }

    // convenience accessor for split.js gutter
    get $gutter() { return this.global$('.gutter') }

    // convenience accessors to question panes
    $builderQuestion(questionId: number) {
        return this.global$(`#form-builder [pif-questionId="${questionId}"]`)
    }

    $previewQuestion(questionId: number) {
        return this.global$(`#form-preview [pif-questionId="${questionId}"]`)
    }

    get nextLanguage(): Language { return this.state.language == 'English' ? 'French' : 'English' }

    // called to update the form preview pane
    updateRenderedPif = () => {
        if (!this.$formTarget.pifViewer('instance')) {
            // we initialize an instance
            this.$formTarget.pifViewer({
                formDefinition: this.state.currentPif!,
                scoreChangeObserver: this.handlePossibleScoreChange, 
            })
        }
        else {
            // we update existing instance
            this.$formTarget.pifViewer('formDefinition', this.state.currentPif!)
        }

        this.setState({ score: this.state.currentPif!.baseScore || 0 })
    }

    // listen and react to user interaction with form preview
    // we delay updates so as not to inundate event loop in the case of rapid-fire input
    handlePossibleScoreChange = () => {
        let { updateRenderedScoreTimeoutId } = this.state
        clearTimeout(updateRenderedScoreTimeoutId)

        updateRenderedScoreTimeoutId = window.setTimeout(this.updateRenderedScore, EditPif.UPDATE_SCORE_TIMEOUT_INTERVAL)

        this.setState({ updateRenderedScoreTimeoutId })
    }

    // calculate and update displayed score
    updateRenderedScore = () => {
        let responses = this.$formTarget.pifViewer('responses') as Responses
        let questionScores = getFormScores(this.state.currentPif!, responses)

        // we update individual question scores by writing them via jQuery
        this.$formTarget.find('[pif-questionId]').each((ix: number, divQuestion: HTMLDivElement) => {
            let $divQuestion = this.global$(divQuestion)
            let questionId = $divQuestion.attr('pif-questionId')!

            let questionScore = questionScores[questionId as any as number]

            if (questionScore) {
                let scoreText = `Score: ${questionScore}`
                let $noAnswer = $divQuestion.find('[pif-markup="no-answer"] .question-score').text(scoreText)

                if ($noAnswer.length == 0) {
                    $divQuestion.find('[pif-markup="no-answer"]')
                        .append(`<div class='question-score float-right'>${scoreText}</div>`)
                }
            }
            else {
                $divQuestion.find('[pif-markup="no-answer"] .question-score').empty()
            }
        })

        // calculate total form score
        let score = Object.keys(questionScores).reduce((formScore, questionId) => {
            return formScore + questionScores[questionId as any as number]
        }, 0)

        score += (this.state.currentPif!.baseScore || 0)

        // update via react
        if (score != this.state.score) this.setState({score})
    }

    handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updatePifWrapper(pif => updateName(pif, e.target.value))
    }

    handleBaseScoreChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updatePifWrapper(pif => updateBaseScore(pif, e.target.valueAsNumber))
    }

    // we update new question addition immediately so that it can be brought into focus
    handleQuestionAdded = () => {
        let updatedPif = addNewQuestion(this.state.currentPif!)
        let newQuestionId = updatedPif.questions[updatedPif.questions.length -1].questionId

        this.setState({ currentPif: updatedPif }, () => {
            this.updateRenderedPif()
            this.handleQuestionToggled(newQuestionId)
        })
    }

    handlePifUpdated: PifUpdatedHandler = (op: PifUpdateOperation) => {
        this.updatePifWrapper(pif => updatePif(pif, op))
    }

    handleAssignButtonClick = () => this.setState({ openedModal: { type: 'assign' } })

    handleModalDismissed = () => {
        this.setState({ openedModal: undefined })
    }

    handleAssignDeployment = async (deployment: Assignment,formTypeKey:string ,copy: boolean) => {
        let bmp = this.context.findById('bmps', deployment.bmpKey)

        if (copy) {
            let newPifId = await json.post(
                Constants.Paths.Api.ProjectInformationForms.Copy,
                { id: this.state.currentPif!.formId, formType: formTypeKey, data: deployment })

            if (newPifId > 0) {
                this.setState({
                    openedModal: { type: 'alert', message: `Form copied and assigned successfully to bmp: ${bmp.name}` }
                }, () => this.props.history.push(Constants.Paths.Web.editPif(newPifId)))
            }
            else {
                this.setState({
                    openedModal: { type: 'alert', message: `There is a form already assigned to bmp: ${bmp.name}` }
                })
            }
        }
        else {
            let success = await json.post(
                Constants.Paths.Api.ProjectInformationForms.Assign,
                { id: this.state.currentPif!.formId, formType: formTypeKey, data: deployment })

            if (success) {
                this.setState({
                    openedModal: { type: 'alert', message: `Form assigned successfully to bmp: ${bmp.name}` }
                }, () => this.loadPif())
            }
            else {
                this.setState({
                    openedModal: { type: 'alert', message: `There is a form already assigned to bmp: ${bmp.name}` }
                })
            }
        }
    }

    handleValidate = () => {
        let isValid = this.$formTarget.pifViewer('validate')

        this.setState({
            openedModal: { type: 'validation', isValid }
        })
    }

    toggleLanguage = () => {
        let language = this.nextLanguage

        this.$formTarget.pifViewer('language', language)

        this.setState({ language })
    }

    handleQuestionToggled = (questionId: number) => {
        if (questionId === this.state.selectedQuestionId) {
            this.setState({ selectedQuestionId: undefined })
        }
        else {
            this.setState({ selectedQuestionId: questionId }, () => {
                // TFS5096
                // nested scrolling, so order of operations is important

                // scroll preview question into view first since it's nested
                this.$previewQuestion(questionId)[0].scrollIntoView({ behavior: 'smooth' })

                // once that's completed scroll builder question into view
                setTimeout(() => {
                    this.$builderQuestion(questionId)[0].scrollIntoView({ behavior: 'smooth' })
                }, 1000)
                // note that 1 second is a bit of a hack, and chrome can take up to 3 seconds for long scrolls
                // better (but still hacky) solution is to build our own scroll listener... something like: 
                // https://stackoverflow.com/a/57867348
            })
        }
    }

    handleSave = async () => {
        let success = await json.post(
            Constants.Paths.Api.ProjectInformationForms.Update,
            { id: this.state.currentPif!.formId, data: this.state.currentPif! })

        if (success) {
            this.loadPif()
            this.setState({
                openedModal: { type: 'alert', message: `Form saved successfully` }
            })
        }
    }

    handleCancel = () => {
        this.setState({ currentPif: this.state.originalPif }, this.updateRenderedPif)
    }

    handleDeleteRequested = () => {
        this.setState({
            openedModal: { type: 'delete' }
        })
    }

    handleDeleteConfirmed = async () => {
        let success = await json.post(
            Constants.Paths.Api.ProjectInformationForms.Delete,
            { id: this.state.currentPif!.formId })

        if (success) {
            this.props.history.push(Constants.Paths.Web.selectPif)
        }
    }

    private idFor = (property: keyof Form) => `pif_${property}`

    render() {
        if (this.isLoading()) return <Loading />

        let unsavedChanges: boolean = !pifsEqual(this.state.originalPif!, this.state.currentPif!)

        let unsavedNotice = unsavedChanges ? 'There are unsaved changes to this form' : ''

        let saveButtons: ReactElement | null = null

        if (unsavedChanges) saveButtons = (
            <ReactStrapForm inline>
                <Button onClick={this.handleSave} className='mr-2' color='primary'>Save</Button>
                <Button onClick={this.handleCancel}>Cancel</Button>
            </ReactStrapForm>
        )

        let form = this.state.currentPif!
        let questions = form.questions

        let questionControls = questions
            .map((q, ix) => {
                if (q.questionId === this.state.selectedQuestionId)
                    return <Question key={q.questionId} index={ix} question={q} questionIds={form.questions.map(q => q.questionId)} onToggle={this.handleQuestionToggled} />
                else
                    return <QuestionCollapsed key={q.questionId} index={ix} question={q} questionIds={form.questions.map(q => q.questionId)} onToggle={this.handleQuestionToggled} />
            })

        let modal = null
        if (this.state.openedModal) {
            switch (this.state.openedModal.type) {
                case 'assign':
                    let formTypeKey = this.formTypeKey()

                    if (formTypeKey) {
                        let intakeId = this.assignments()[0].intakeKey
                        modal = <AssignModal
                            projectInformationFormId={this.pifId()}
                            formTypeValue={this.formType()}
                            formTypeKey={formTypeKey}
                            allDeploymentsPif={this.state.allAssignmentsPIF}
                            allDeploymentsSystemBonus={this.state.allAssignmentsSystemBonus}
                            intakeKey={intakeId}
                            onAssign={this.handleAssignDeployment}
                            onDismiss={this.handleModalDismissed} />
                        }
                    break
                case 'alert':
                    modal = <AlertModal
                        header='Form Updated'
                        message={this.state.openedModal.message}
                        onDismiss={this.handleModalDismissed} />
                    break
                case 'validation':
                    let [color, message] = this.state.openedModal.isValid ?
                        ['success', 'Form validated successfully.  It could be submitted.'] : 
                        ['danger', 'Form did not validate.  It would have to be completed prior to submission.'] 

                    let alert = <Alert color={color}>{message}</Alert>

                    modal = <AlertModal
                        header='Validation'
                        onDismiss={this.handleModalDismissed}>
                            {alert}
                        </AlertModal>
                    break
                case 'delete':
                    modal = <ConfirmModal
                        header='Confirm Delete'
                        onConfirm={this.handleDeleteConfirmed}
                        onDismiss={this.handleModalDismissed}
                    >
                        Are you sure you want to delete {form.name}?
                    </ConfirmModal>

                    break;
                default:
                    assertNever(this.state.openedModal)
            }
        }

        return (
            <EditPifContext.Provider value={{ updatePif: this.handlePifUpdated }}>
                {modal}
                <div id='edit-pif'>
                    <div id='form-builder' className='edit-pane'>
                        <ReactStrapForm>
                            <FormGroup row>
                                <Col md={{ size: 9 }}>
                                    <h5 className='mt-2'>Project Information Form Details:</h5>
                                </Col>
                                <Col>
                                    <Button close onClick={this.handleDeleteRequested} title='Delete Question' color='danger'></Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for={this.idFor('name')} md={{size: 4}}>Form Name: </Label>
                                <Col>
                                    <Input type='text' id={this.idFor('name')} value={form.name} onChange={this.handleNameChanged} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for={this.idFor('baseScore')} md={{size: 4}}>Base Score: </Label>
                                <Col>
                                    <Input type='number' id={this.idFor('baseScore')} value={form.baseScore} onChange={this.handleBaseScoreChanged} />
                                </Col>
                            </FormGroup>                          
                            <FormGroup row>
                                <Col md={8}>
                                    <BmpTable deployments={this.assignments()} projectCategories={this.context.bmps!} intakes={this.context.intakes!} />
                                </Col>
                                <Col md={4}>
                                    <Button
                                        disabled={unsavedChanges}
                                        onClick={() => this.setState({ openedModal: { type: 'assign' } })}>
                                        Assign to new BMP/Intake
                                    </Button>
                                </Col>
                            </FormGroup>
                        </ReactStrapForm>
                        {questionControls}
                        <div className='text-right'>
                            <Button onClick={this.handleQuestionAdded}>Add Question</Button>
                        </div>
                    </div>
                    <div id='form-preview' className='edit-pane' ref={this.formPreviewRef}>
                        <div id={EditPif.RENDERED_PIF_DIV_ID}></div>
                    </div>
                </div>
                <footer id='edit-footer'>
                    <Container fluid>
                        <Row className='m-1'>
                            <Col md={2}>
                                <Link to={Constants.Paths.Web.selectPif} className='btn btn-info'>&lArr; Back</Link>
                            </Col>
                            <Col md={2}>
                                {unsavedNotice}
                            </Col>
                            <Col md={2}>
                                {saveButtons}
                            </Col>
                            <Col md={2}>
                                <h4>Score: {this.state.score}</h4>
                            </Col>
                            <Col md={2} className='text-center'>
                                <Button onClick={this.toggleLanguage}>Switch to {this.nextLanguage}</Button>
                            </Col>
                            <Col md={2} className='text-right'>
                                <Button color='success' onClick={this.handleValidate}>Validate &#10004;</Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </EditPifContext.Provider>
        )
    }
}

