import React from 'react';
import { Route, Redirect, RouteComponentProps, Switch, withRouter } from 'react-router';

import { Constants, Settings } from './Constants'
import { Layout } from './components/Layout';

import { json, setErrorHandler, ResponseHandler } from './http'

import Loading from './components/Loading'

import { Login } from './pages/Login';
import { SelectPif } from './pages/SelectPif';
import { EditPif } from './pages/EditPif';
import { CreatePif } from './pages/CreatePif';

import { HierarchyItem } from './models/Hierarchy'

import { AppContext, findById } from './AppContext'
import { ErrorModal } from './components/modals/ErrorModal';
import { AuthenticationResult, Authentication, AuthorizedRoute, LoginRoute } from './Authentication';

interface Loadables {
    formTypes: Array<HierarchyItem>
    applicationTypes: Array<HierarchyItem>
    intakes: Array<HierarchyItem> 
    bmps: Array<HierarchyItem> 
    settings: Settings
}

const requiredLoadables: Array<keyof Loadables> = ['applicationTypes', 'intakes', 'bmps', 'settings']

export interface AppProps {
}

export interface AppState {
    loadables: Partial<Loadables>,
    fluidWidth: boolean,
    errorHtml?: string, 
}

// users navigating to root will be directed to login, or to select a pif
// depending on whether or not they're loggged in
const RootRedirect = () => {
    if (!Authentication.isAuthorized())
        return <Redirect to={Constants.Paths.Web.login} />
    else 
        return <Redirect to={Constants.Paths.Web.selectPif} />}

// users navigating to an invalid path will be directed to log in, or shown a 404 message
// depending on whether or not they're loggged in
const PageNotFound = () => {
    if (!Authentication.isAuthorized())
        return <Redirect to={Constants.Paths.Web.login} />
    else 
        return <h1>404: Page not found</h1>
}

class AppInnards extends React.Component<RouteComponentProps<AppProps>, AppState> {
    static displayName = 'App'

    constructor(props: RouteComponentProps<AppProps>) {
        super(props)
        setErrorHandler(this.handleHttpError);

        this.state = { loadables: {}, fluidWidth: false }
    }

    isLoading = () => Authentication.isAuthorized() && requiredLoadables.some(key => !this.state.loadables[key])

    async componentDidMount() {
        this.getLoadables()
    }

    async getLoadables(cb?: () => void) {
        if (Authentication.isAuthorized()) {
            let [formTypes, applicationTypes, intakes, bmps, settings] = await Promise.all([
                Constants.Paths.Api.Hierarchy.FormTypes,
                json.get(Constants.Paths.Api.Hierarchy.ApplicationTypes),
                json.get(Constants.Paths.Api.Hierarchy.Intakes),
                json.get(Constants.Paths.Api.Hierarchy.Bmps),
                json.get(Constants.Paths.Api.Settings)
            ])

            this.setState({ loadables: { formTypes,applicationTypes, intakes, bmps, settings } }, () => cb && cb())
        }
    }

    // enable the builder component to fill the full width of the page 
    setFluidWidth = (fluidWidth = true) => {
        this.setState({ fluidWidth })
    }

    handleHttpError: ResponseHandler = async (response) => {
        if (response.status == 401) {
            // we are unauthorized
            Authentication.clear()
            this.props.history.push(Constants.Paths.Web.login)
        }
        else {
            let errorHtml = await response.text()
            this.setState({ errorHtml })
        }
    }

    dismissError = () => {
        this.setState({ errorHtml: undefined })
    }

    handleLogin = (authenticated: AuthenticationResult) => {
        if (authenticated.token) {
            Authentication.set(authenticated)
            this.getLoadables(() => {
                this.props.history.push(Constants.Paths.Web.selectPif)
            })
        }
    }

    handleLogout = () => {
        Authentication.clear()
        this.forceUpdate()
    }

    render() {
        if (this.isLoading()) return <Loading />

        else {
            let loadables = this.state.loadables as Loadables

            return (
                <AppContext.Provider value={{ ...loadables, findById, setFluidWidth: this.setFluidWidth, login: this.handleLogin, logout: this.handleLogout }}>
                    <Layout fluidWidth={this.state.fluidWidth}>
                        <Switch>
                            <LoginRoute path={Constants.Paths.Web.login} component={Login} />
                            <AuthorizedRoute path={Constants.Paths.Web.selectPif} component={SelectPif} />
                            <AuthorizedRoute path={Constants.Paths.Web.editPif()} component={EditPif} />
                            <AuthorizedRoute path={Constants.Paths.Web.createPif()} component={CreatePif} />
                            <Route exact path='/' component={RootRedirect} />
                            <Route component={PageNotFound} />
                        </Switch>
                        <ErrorModal errorHtml={this.state.errorHtml} onDismiss={this.dismissError}></ErrorModal>
                    </Layout>
                </AppContext.Provider>
            );
        }
    }
}

export const App = withRouter(AppInnards)

export default App
