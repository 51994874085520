import React from 'react';
import { Table as ReactTable } from 'reactstrap'

export type Stringified<T> = {
    [key in keyof T]: string
}

export interface TableProps<T> {
    columns: Array<keyof T>
    data: Array<T>
    headerNames?: Stringified<T>
}

export function Table<T>(props: TableProps<T>) {
    // can't use object.keys for some reason... TODO: look into this
    let headers: Array<React.ReactElement> = props.columns
        .map(column => props.headerNames && props.headerNames[column] || column)
        .map((headerName, ix) => <th key={ix}>{headerName}</th>)

    let rows = props.data.map((rowData, rowIx) => {
        let cells: Array<React.ReactElement> = props.columns
            .map((column, cellIx) => <td key={cellIx}>{rowData[column]}</td>)
        return <tr key={rowIx}>{cells}</tr>
    })

    return (
        <ReactTable striped>
            <thead>
                <tr>
                    {headers}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </ReactTable>
    )
}

export default Table
